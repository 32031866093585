import { Swiper, SwiperSlide } from 'swiper/react/swiper-react';
import SwiperCore, { Mousewheel, Pagination, Scrollbar, EffectFade } from 'swiper';


// Import Swiper styles
import 'swiper/swiper-bundle.min.css';
import {useEffect, useRef, useState} from "react";
import bgFirst from "./assets/img/bgFirst.png";
import image2 from "./assets/img/2.jpg";
import image3 from "./assets/img/3.jpg";
import image4 from "./assets/img/4.jpg";
import image5 from "./assets/img/5.jpg";
import image6 from "./assets/img/6.jpg";

export const Slides = () => {


/// &darr;	↓



    SwiperCore.use([Mousewheel, EffectFade, Pagination, Scrollbar])
    return (
        <Swiper className={'swiper h-full'}
                effect={'fade'}
                fadeEffect={{crossFade:true}}
                direction={'vertical'}
                mousewheel={true}
                slidesPerView={1}
                spaceBetween={30}
                navigation={false}
                pagination={{
                    bulletActiveClass: 'paginationBulletActive',
                    bulletClass: 'paginationBullet',
                    clickable: true,}}
                lazy={true}
        >

            <SwiperSlide >
                <div className={'h-full w-full'} style={{
                    filter: 'hue-rotate(240deg) sepia(10%)',
                    backgroundImage:`url(${bgFirst}`,
                    backgroundSize:'contain',
                    backgroundRepeat:'no-repeat',
                    backgroundPositionY:'center'}}>
                    <div className={'flex flex-col text-white justify-around content-center h-full'}>
                        <div className={ 'text-center uppercase  '} style={{filter:"unset"}}>
                            <h1 className={'text-5xl '} style={{
                                position: 'absolute',
                                filter: 'drop-shadow(1px 1px 16px red)',
                                width: '100%',
                                marginTop: '12%'
                            }}>
                                Welcome to the quasar metaverse
                            </h1>
                        </div>
                        <div className={ 'text-center  text-2xl'} >
                            <div className={' uppercase'} >
                                quick start guide
                            </div>
                        </div>

                        <div className={'animate-bounce w-full absolute h-8 bottom-2 uppercase text-white text-2xl z-10 text-center'}>
                            &#8659; Scroll/swipe down &#8659;
                        </div>
                    </div>
                </div>
            </SwiperSlide>
            <SwiperSlide >

                <div className={'h-full flex flex-row justify-center'} >
                    <img src={`${image2}`} alt="" style={{
                        width:"90%",
                        minWidth:"984px",
                        height:'auto',
                    }}/>
                </div>
            </SwiperSlide>
            <SwiperSlide >

                <div className={'h-full flex flex-row justify-center'}>

                    <img src={`${image3}`} alt="" style={{
                        width:"90%",
                        minWidth:"984px",
                        height:'auto',
                    }}/>
                </div>
            </SwiperSlide>
            <SwiperSlide >
                <div className={'h-full flex flex-row justify-center'} >
                    <img src={`${image4}`} alt="" style={{
                        width:"90%",
                        minWidth:"984px",
                        height:'auto',
                    }}/>
                </div>
            </SwiperSlide>
            <SwiperSlide >
                <div className={'h-full flex flex-row justify-center'} >
                    <img src={`${image5}`} alt="" style={{
                        width:"90%",
                        minWidth:"984px",
                        height:'auto',
                    }}/>
                </div>
            </SwiperSlide>
            <SwiperSlide >
                <div className={'h-full flex flex-row justify-center'}>
                    <img src={`${image6}`} alt="" style={{
                        width:"90%",
                        minWidth:"984px",
                        height:'auto',
                    }}/>
                </div>
            </SwiperSlide>
        </Swiper>
    );
};
