import React from 'react';
import './App.css';
import {Slides} from "./Slides";
import bg from "./assets/img/bg.jpg";

function App() {
  return (
    <div className="App  bg-black h-screen w-screen flex flex-col justify-center"
         style={{backgroundImage:`url(${bg})`, backgroundSize:'cover', fontFamily:'Play'}}>
        <div className={'mx-auto container'} >
                <Slides />
        </div>

    </div>
  );
}

export default App;
